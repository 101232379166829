import { useSignal } from "@preact/signals";
import LoginForm from './LoginForm.tsx';
import RegisterForm from './RegisterForm.tsx';
import RegisterCodeCheck from './RegisterCodeCheck.tsx';

export default function EnterPage() {
    const form = useSignal("login")

    const login = useSignal("")
    const password = useSignal("")
    const code = useSignal("")

    function changeForm(name: string) {
        form.value = name
    }

    function changeData(newLogin: string, newPassword: string, newCode: string) {
        login.value = newLogin
        password.value = newPassword
        code.value = newCode
    }

    return (
        <>
            {form.value == "register" && <RegisterForm changeForm={changeForm} changeData={changeData} />}
            {form.value == "login" && <LoginForm changeForm={changeForm} />}
            {form.value == "checkCode" && <RegisterCodeCheck changeForm={changeForm} login={login.value} code={code.value} password={password.value} />}
        </>
    );
}